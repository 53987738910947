/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";

import './Works.css';

// Import ../../assets/recentprojects/
import MentorcliQ from '../../assets/recentprojects/mentorcliq-mockup.png';
import AlignAI from '../../assets/recentprojects/AlignAi-Thumbnail.png';
import UHM from '../../assets/recentprojects/uhm-brand-websites-mockup.png';
import Matrix from '../../assets/recentprojects/Matrix-Trade-Institute-Website.png';
import Verasana from '../../assets/recentprojects/Verasana-Thumbnail.png';
import CommandPlastic from '../../assets/recentprojects/CommandPlastic-Thumbnail.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: '3em',
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    {
      id: 1,
      title: 'MentorcliQ',
      subtitle: 'HR/Mentoring SaaS | Web & Mobile Product Design',
      description: `I'm currently 1/2 of the UI/UX team at MentorcliQ, 
      a mentoring software solution that helps organizations launch, support, 
      and grow high-impact employee mentoring programs. Our approach drives 
      employee participation and satisfaction through an engaging user experience 
      and supporting training resources. MentorcliQ makes it easy to manage 
      multiple mentoring and talent development programs from a single place.`,
      alter: 'MentorcliQ',
      image: `${MentorcliQ}`,
    },
    {
      id: 2,
      title: 'AlignAI',
      subtitle: 'AI SaaS/ Education | Website Design & Development',
      description: `I assited the team at AlignAI with website design and development to tell a story about their product and service offers,
      assisting companies streamline and document their systems and data.`,
      image: `${AlignAI}`,
    },
    {
      id: 3,
      title: 'Union Home Mortgage',
      subtitle: 'Mortgage/Finance | Website & Software Design & Development',
      description: `During a 5-year stretch at Union Home Mortgage, I became the first in-house Front-End Developer and UX Designer.
      I created a design system in tandom to redesign our collection of websites for each, including UHM.com, MutualTitleAgency.com, UHMFoundation.org, and UHMDirect.com (formerly vLoan.com).
      In addition to customer-facing websites, I also designed a suite of web apps for our employees and internal teams to use to automate the mortgage process.`,
      alter: 'Union Home Mortgage',
      image: `${UHM}`,
    },
    {
      id: 4,
      title: 'Matrix Trade Institute',
      subtitle: 'Startup/Education | Website Design & Development',
      description: `I worked alongside the team at Matrix Trade Institute for over a year on website redesigns and lead generation. 
      The team had an Umbraco website that needed a certified developer to help continue to make enhancements and lead generation funnels
      to capture prospective students' interest.`,
      alter: 'Matrix Trade Institute',
      image: `${Matrix}`,
    },
    {
      id: 5,
      title: 'Verasana',
      subtitle: 'Startup/Mobile Education | Website Design & Development',
      description: `I worked with the CEO/Founder of Verasana to design and develop a website using Hubspot CMS.`,
      alter: 'Startup Project',
      image: `${Verasana}`,
    },
    {
      id: 6,
      title: 'Command Plastic',
      subtitle: 'Plastic Fabrication/Manufacturing | Website Design & Development',
      description: `I worked with the CEO/Owner of Command Plastic to design and develop a website using WordPress.`,
      alter: 'Startup Project',
      image: `${CommandPlastic}`,
    },
  ]);

  return (
    <section id="works">
      <Container component="main" className={classes.main} maxWidth="md">
        <div className={classes.heading}>
          <Typography variant="h5" component="h2">
            UI/UX Design & Development Portfolio
          </Typography>
        </div>
        {projects.map((project) => (
          <div className="project project__reverse" key={project.id}>
            <div className="__img_wrapper">
              <img src={project.image} alt={project.alter} />
            </div>
            <div className="__content_wrapper">
              <h3 className="title">
                {project.title}
              </h3>
              <h4 className="subtitle">
                {project.subtitle}
              </h4>
              <p className="description">
                {project.description}
              </p>
            </div>
          </div>
        ))}
      </Container>
    </section>
  );
};
