import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Headshot from '../../assets/headshot.jpg';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    marginTop: "auto",
    marginBottom: "auto",
  },
  heading: {
    marginLeft: theme.spacing(25),
    "@media (max-width: 768px)": {
      marginLeft: theme.spacing(0),
      textAlign : 'center'
    },
  },
  jobs: {
    "@media (max-width: 768px)": {
      fontSize: '2.5rem',
    },
  }
}));

export const Content = () => {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.main} maxWidth="md">
      <div className={classes.heading}>
        <Typography variant="h5" component="h2">
          Hello! I'm Tessa Newbacher.
        </Typography>
        <Typography variant="h1" component="h1" className={classes.jobs}>
          UI/UX Designer, <br />
          Web Developer,<br />
          &amp; Artist
        </Typography>
      </div>
    </Container>
  );
};
