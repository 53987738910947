import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// Import ../../assets/recentprojects/
import LogoSVG from '../../assets/tessa-newbacher-logo-emblem.svg';
import './Logo.css';

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
    width: "100%",
    height: "100%"
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return (
    <div className="logo">
      <img src={LogoSVG} className={classes.svgHover} alt="Tessa Newbacher | UI / UX Designer & Developer" />
    </div>
  );
};
